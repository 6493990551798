import React, { useState } from 'react';
import axios from 'axios';
import {
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormButton,
  Text,
  FormFields,
  FormItem,
  Message,
  MsgInput,
  Container,
  Icon,
} from './FormElements';
import { FaChevronCircleLeft } from 'react-icons/fa';

const RequestDemo = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    company: '',
    country: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post('/submit-form', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setEmailSent(true);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <>
      <Container>
        <FormWrap>
          <Icon to='/'>
            <FaChevronCircleLeft />
          </Icon>
          <FormContent>
            <FormH1>{emailSent ? 'Email Sent Successfully' : 'Request a Call'}</FormH1>
            {emailSent ? (
              <Text>Thank you for your interest. We will contact you soon.</Text>
            ) : (
              <Form onSubmit={handleSubmit}>
                <FormFields>
                  <FormItem>
                    <FormLabel htmlFor='for'>First Name</FormLabel>
                    <FormInput
                      type='text'
                      name='firstName'
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </FormItem>
                  <FormItem>
                    <FormLabel htmlFor='for'>Last Name</FormLabel>
                    <FormInput
                      type='text'
                      name='lastName'
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </FormItem>

                  <FormItem>
                    <FormLabel htmlFor='for'>Mobile</FormLabel>
                    <FormInput
                      type='text'
                      name='mobile'
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </FormItem>

                  <FormItem>
                    <FormLabel htmlFor='for'>Email</FormLabel>
                    <FormInput
                      type='email'
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </FormItem>

                  <FormItem>
                    <FormLabel htmlFor='for'>Company</FormLabel>
                    <FormInput
                      type='text'
                      name='company'
                      value={formData.company}
                      onChange={handleChange}
                      required
                    />
                  </FormItem>

                  <FormItem>
                    <FormLabel htmlFor='for'>Country</FormLabel>
                    <FormInput
                      type='text'
                      name='country'
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                  </FormItem>
                </FormFields>
                <Message>
                  <FormLabel htmlFor='for'>Message</FormLabel>
                  <MsgInput
                    rows='5'
                    name='message'
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Message>
                {loading ? (
                  <FormButton disabled>Loading...</FormButton>
                ) : (
                  <FormButton type='submit'>Send Request</FormButton>
                )}
              </Form>
            )}
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default RequestDemo;
