import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjOne } from '../components/InfoSection/Data'
import { navLogo } from '../components/Navbar/Data'
import OurServices from '../components/OurServices'
import { homeObjTwo } from '../components/OurServices/Data'
import OurPresence from '../components/OurPresence'
import { homeObjThree } from '../components/OurPresence/Data'
import Foorter from '../components/Footer'

const Home = () => {
    const [isOpen, setIsOpen] = useState(false)
    const toggle = () => {
        setIsOpen(!isOpen)
    }

  return (
    <>
    <Sidebar isOpen={isOpen} toggle={toggle}/>
    <Navbar toggle={toggle} {...navLogo}/>
    <HeroSection />
    <InfoSection {...homeObjOne} />
    <OurServices {...homeObjTwo} />
    <OurPresence {...homeObjThree}/>
    <Foorter />
    </>
  )
}

export default Home