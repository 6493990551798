export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine1: 'Comprehensive Digital Solutions',
    headline1: 'Comprehensive digital solutions help businesses succeed in the digital age',
    description1: 'Strategic Digital Marketing:',
    description2: 'Boost your online presence through data-driven strategies encompassing SEO, social media, and compelling content, ensuring maximum brand visibility and engagement.',
    description3: 'Innovative Website Development:',
    description4: 'From eye-catching designs to seamless functionality, we create custom websites that captivate visitors and deliver an exceptional user experience.',
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/svg-1.svg'),
    alt: 'Expert',
    dark: true,
    primary: true,
    darkText: false,


    topLine2: 'Empowering Control with Admin Dashboards',
    headline2: ' Effortless Management and Informed Decision-making',
    description5: 'Streamlined Management:',
    description6: 'Our expert developers craft intuitive admin dashboards, giving you centralized control to efficiently manage content, monitor analytics, and make real-time decisions.',
    description7: 'Data-Driven Insights:',
    description8: 'Harness the power of data with insightful visualizations, enabling you to make informed business decisions, track performance, and drive growth.',
};