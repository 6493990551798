import React from 'react';
import Video1 from '../../videos/video-6.webm';
import Video2 from '../../videos/video-5.webm';
import { Button } from '../ButtonElements';
import { useState } from 'react';

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle1,
    Subtitle,
    Column2,
    HeroBg,
    VideoBg,
    DescriptionWrapper,
    BtnWrap
}
    from './InfoElements'
const InfoSection = (
    { lightBg,
        id,
        primary,
        dark,
        dark2,
        imgStart,
        topLine1,
        topLine2,
        lightText,
        headline1,
        headline2,
        darkText,
        description1,
        description2,
        description3,
        description4,
        description5,
        description6,
        description7,
        description8,
    }) => {
    const [showFullDescription1, setShowFullDescription1] = useState(false);
    const [showFullDescription2, setShowFullDescription2] = useState(false);

    const toggleDescription1 = () => {
        setShowFullDescription1(!showFullDescription1);
    };
    const toggleDescription2 = () => {
        setShowFullDescription2(!showFullDescription2);
    };
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow imgStart={imgStart}>
                        <Column1>
                            <HeroBg>
                                <VideoBg autoPlay loop muted src={Video1} type='video/webm' />
                            </HeroBg>
                            <TextWrapper>
                                <TopLine>{topLine1}</TopLine>
                                <Heading lightText={lightText}>{headline1}</Heading>
                                <DescriptionWrapper>
                                    {showFullDescription1 && (
                                        <>
                                            <Subtitle1 darkText={darkText}>{description1}</Subtitle1>
                                            <Subtitle darkText={darkText}>{description2}</Subtitle>
                                            <Subtitle1 darkText={darkText}>{description3}</Subtitle1>
                                            <Subtitle darkText={darkText}>{description4}</Subtitle>
                                        </>
                                    )}
                                </DescriptionWrapper>
                                {showFullDescription1 ? (
                                    <BtnWrap>
                                        <Button onClick={toggleDescription1}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            Read Less
                                        </Button>
                                    </BtnWrap>
                                ) : (
                                    <BtnWrap>
                                        <Button onClick={toggleDescription1}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            Read More
                                        </Button>
                                    </BtnWrap>
                                )}
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <HeroBg>
                                <VideoBg autoPlay loop muted src={Video2} type='video/webm' />
                            </HeroBg>
                            <TextWrapper>
                                <TopLine>{topLine2}</TopLine>
                                <Heading lightText={lightText}>{headline2}</Heading>
                                <DescriptionWrapper>
                                    {showFullDescription2 && (
                                        <>
                                            <Subtitle1 darkText={darkText}>{description5}</Subtitle1>
                                            <Subtitle darkText={darkText}>{description6}</Subtitle>
                                            <Subtitle1 darkText={darkText}>{description7}</Subtitle1>
                                            <Subtitle darkText={darkText}>{description8}</Subtitle>
                                        </>
                                    )}
                                </DescriptionWrapper>
                                {showFullDescription2 ? (
                                    <BtnWrap>
                                        <Button onClick={toggleDescription2}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            Read Less
                                        </Button>
                                    </BtnWrap>
                                ) : (
                                    <BtnWrap>
                                        <Button onClick={toggleDescription2}
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            Read More
                                        </Button>
                                    </BtnWrap>
                                )}
                            </TextWrapper>
                        </Column2>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default InfoSection