import React from 'react'
import { Button } from '../ButtonElements';
import { useState } from 'react';
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    TextWrapper,
    Heading,
    DescriptionWrapper,
    DescriptionText,
    CardIntro
}
    from './WhyUsElements'
import CardSection from './CardSection';
import FeatureCards from './FeatureCards';
const WhyUs = (
    {
        lightBg,
        id,
        lightText,
        headline1,
        description1,
        description2,
        description5,

    }
) => {

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };
    return (
        <>
            <InfoContainer lightBg={lightBg} id={id}>
                <InfoWrapper>
                    <InfoRow>
                        <Column1>
                            <TextWrapper >
                                <CardIntro>
                                    {description5}
                                </CardIntro>
                                <CardSection />

                                {/* <TopLine>{topLine1}</TopLine> */}
                                <Heading lightText={lightText}>{headline1}</Heading>
                                <DescriptionWrapper>
                                    <DescriptionText>
                                        {description1}
                                    </DescriptionText>
                                    <DescriptionText>
                                        {description2}
                                    </DescriptionText>
                                
                                </DescriptionWrapper>
                                {/* <FeatureCards /> */}
                            </TextWrapper>
                        </Column1>
                    </InfoRow>
                </InfoWrapper>
            </InfoContainer>
        </>
    )
}

export default WhyUs