import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: #E5E5E5;
    padding: 20px;

    @media screen and (max-width : 768px) {
        padding: 100px 0;
    }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 200px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoRow = styled.div`
    display: grid;
    align-items: center;
    margin-top: 2rem;
`;

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0px;
    grid-area: col1;
    width: 100%;
`;

export const Title = styled.h2`
color: '#000',
`;


export const VideoBg = styled.video`
height: 80vh;
width: 100%;
outline: #e5e5e5 solid 10px;
outline-offset: -4px;

@media screen and (max-width: 480px) {
  height: 16vh;
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  height: 35vh;
}


`;
export const MapImage = styled.img`
  height: 80vh;
  margin-bottom: 10px;
  width: 100%;

  @media screen and (max-width: 480px) {
    height: 16vh;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 35vh;
  }
`;



export const TextWrapper = styled.div`
    max-width: 100%;
    padding-top: 0;
`;

export const TopLine = styled.p`
    color: #2C8BB0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`;

export const Heading = styled.h2`
    margin-bottom: 50px;
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    color: #000;
    text-align: center;
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`;


export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
`;

export const DescriptionWrapper = styled.div`
  overflow: hidden;
`;

export const DescriptionText = styled.p`
max-width: 100%;
font-size: 18px;
line-height: 24px;
color: #000;
text-align: center
`;