import React from 'react'

const AnimationComponent = () => {
    return (
        <div class="main">
            <h1>Your Partner in Digital Growth
                <div class="roller">
                    <span id="rolltext">Website Development<br />
                        Digital Marketing<br />
                        Business Lead Generation<br />
                        Admin Dashboard<br />
                        {/* <span id="spare-time">Over 1 Million PO's Processed</span><br /> */}
                    </span>
                </div>
            </h1>
        </div>
    );
};

export default AnimationComponent